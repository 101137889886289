<template>
  <div
    v-if="precioInitial > 0"
    class="d-none d-md-block"
  >
    <!--  style="position: fixed; z-index: 6; width: available" -->
    <v-col
      ref="statiCard"
      cols="12"
      md="12"
      class=""
    >
      <v-row
        v-if="showOnlyGestor"
        class="mb-3"
      >
        <v-card
          width="100%"
          class="mx-2 pa-4 pt-0"
        >
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                <h1
                  primary-title
                  class="pa-0"
                >
                  PVP
                </h1>
              </v-col>
              <!--CALCULADORA-->
              <v-row class="pt-2">
                <v-col
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="markup"
                    label="Mark-up"
                    hide-details
                    outlined
                    width="auto"
                    class="descuento"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueMarkup"
                    hide-details
                    dense
                    class="mt-0 pt-0 descuento"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      class="descuento"
                      value="$"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="agregarDescuento"
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="descuento"
                    class="descuento"
                    :label="$t('lbl.descuento')"
                    hide-details
                    outlined
                    width="auto"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="agregarDescuento"
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueDescuento"
                    hide-details
                    dense
                    class="mt-0 pt-0"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      class="descuento"
                      value="$"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10">
                  <v-checkbox
                    v-model="agregarDescuento"
                    :label="$t('lbl.addDescuento')"
                    dense
                    hide-details
                    outlined
                    class="descuento my-0 py-0"
                    value="1"
                    @change="changeAgregarDescuento"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="2"
                  class="align-self-center align-right"
                >
                  <v-btn
                    color="primary"
                    @click="applyMarkups"
                  >
                    {{ $t('btn.apply') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="mt-12"
            >
              <v-row class="d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.precioInitial') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioInitial | currency }}
                  </p>
                </div>
              </v-row>
              <v-row class="mt-6 d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conMarkups') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithMarkup | currency }}
                  </p>
                </div>
              </v-row>
              <v-row
                v-if="agregarDescuento"
                class="mt-6 d-flex justify-center align-center"
              >
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conDescuento') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithDescuento | currency }}
                  </p>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <!--MOSTRAR PARA EL GESTOR-->
      <fragment v-if="showOnlyGestor">
        <v-row class="my-2">
          <v-card
            width="100%"
            class="mx-2 pa-4 pt-0"
          >
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-1">
                {{ $t('lbl.tarif') }}
              </h4>
              <h4>
                <h4>
                  {{ precioTotal | currency }}
                </h4>
              </h4>
            </div>
            <fragment
              v-for="(room, iRom) in cotizadorHotelsRomms"
              :key="iRom"
            >
              <fragment v-if="!showOcupationRoomHotel && room.room_id">
                <div class="d-flex justify-space-between align-center mb-0 pb-0">
                  <span
                    class="mb-0 pb-0"
                    style="font-size: 14px"
                  >
                    {{ room.cant }} x
                    {{
                      roomsNomAll.filter(e => e.id === room.room_id).length > 0
                        ? roomsNomAll.filter(e => e.id === room.room_id)[0].name
                        : ''
                    }}
                  </span>
                  <span style="font-size: 14px"> {{ room.priceWithDescuento | currency }} </span>
                </div>
                <div class="mt-0 pt-0">
                  <span
                    class="pt-0 mt-0 ml-5"
                    style="font-size: 10px"
                  >
                    {{ $t('lbl.plan') }}: {{ room.plan }}
                    <span class="ml-1">{{ $t('lbl.uso') }}: </span>
                    <span class="text-uppercase">{{ room.uso }}</span>
                    <span v-if="room.childs > 0">+ {{ $tc('lbl.cantChild', room.childs) }}</span>
                  </span>
                </div>
              </fragment>
            </fragment>
            <div class="d-flex justify-space-between align-center ml-2 mt-3">
              <span>
                <span style="font-size: 14px">
                  {{ $t('lbl.incluyeMargenCom') }}
                </span>
                <fragment
                  v-if="cotizadorHotelsSelectMarkups.markup !== 0 && cotizadorHotelsSelectMarkups.valueMarkup !== null"
                >
                  ({{ cotizadorHotelsSelectMarkups.markup }}
                  <span v-if="cotizadorHotelsSelectMarkups.valueMarkup === '%'">%</span>
                  <span v-else-if="cotizadorHotelsSelectMarkups.valueMarkup === '$'">USD</span>)
                </fragment>
              </span>
              <span style="font-size: 14px">
                {{
                  cotizadorHotelsResultSelect.priceWithMarkup > 0
                    ? cotizadorHotelsResultSelect.priceWithMarkup
                    : 0 | currency
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between align-center ml-2 mt-0">
              <span>
                <span style="font-size: 14px">
                  {{ $t('lbl.incluyeDescuento') }}
                </span>
                <fragment
                  v-if="
                    cotizadorHotelsSelectMarkups.descuento !== 0 && cotizadorHotelsSelectMarkups.valueDescuento !== null
                  "
                >
                  ({{ cotizadorHotelsSelectMarkups.descuento }}
                  <span v-if="cotizadorHotelsSelectMarkups.valueDescuento === '%'">%</span>
                  <span v-else-if="cotizadorHotelsSelectMarkups.valueDescuento === '$'">USD</span>)
                </fragment>
              </span>
              <span style="font-size: 14px">
                {{
                  cotizadorHotelsResultSelect.priceWithDescuento > 0
                    ? cotizadorHotelsResultSelect.priceWithDescuento
                    : 0 | currency
                }}
              </span>
            </div>
          </v-card>

          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-1">
                {{ $t('lbl.cargoSuplement') }}
              </h4>
              <h4>
                <h4>
                  {{ 0 | currency }}
                </h4>
              </h4>
            </div>
          </v-card>

          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-1">
                {{ $t('lbl.totalPay') }}
              </h4>
              <h4>
                <h4>
                  {{ precioTotal | currency }}
                </h4>
              </h4>
            </div>
            <fragment>
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.payOnline') }}
                </h4>
                <h4>
                  {{ precioTotal | currency }}
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.tarif') }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ precioTotal | currency }}
                </span>
              </div>

              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.payAloja') }}
                </h4>
                <h4>
                  {{ 0 | currency }}
                </h4>
              </div>
            </fragment>
          </v-card>

          <!--<v-card
            v-if="precioSuplementPayOnline > 0 || precioSuplementPayRentator > 0"
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <v-card-title
              primary-title
              class="px-0 py-2"
            >
              {{ $t('lbl.otherCarge') }}
            </v-card-title>
            <fragment v-if="precioSuplementPayOnline > 0">
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.payOnline') }}
                </h4>
                <h4>
                  {{ precioSuplementPayOnline | currency }}
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <h6>
                  {{ $t('lbl.seguro') }}
                </h6>
                <h6>
                  {{ cotizadorHotelsResultSelect.tarifaSeguro | currency }}
                </h6>
              </div>
              <div
                v-for="(suplemento, indSupl) in cotizadorHotelsResultSelect.suplementos"
                :key="indSupl"
                class="d-flex justify-space-between align-center ml-2"
              >
                <fragment v-if="!!suplemento.online && !!suplemento.check">
                  <h6>
                    {{ suplemento.suplemento.name }}
                  </h6>
                  <h6>
                    {{ suplemento.priceAll | currency }}
                  </h6>
                </fragment>
              </div>
            </fragment>

            <fragment v-if="precioSuplementPayRentator > 0">
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.payRent') }}
                </h4>
                <h4>
                  {{ precioSuplementPayRentator | currency }}
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <h6>
                  {{ $t('lbl.depositoGarantia') }}
                </h6>
                <h6>
                  {{ cotizadorHotelsResultSelect.tarifaGarantia | currency }}
                </h6>
              </div>
              <div
                v-for="(suplemento, indSupl) in cotizadorHotelsResultSelect.suplementos"
                :key="indSupl"
                class="d-flex justify-space-between align-center ml-2"
              >
                <fragment v-if="!suplemento.online && !!suplemento.check">
                  <h6>
                    {{ suplemento.suplemento.name }}
                  </h6>
                  <h6>
                    {{ suplemento.priceAll | currency }}
                  </h6>
                </fragment>
              </div>
            </fragment>
          </v-card>

          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <div class="d-flex justify-space-between align-center">
              <h3 class="my-2">
                {{ $t('lbl.totalPay') }}
              </h3>
              <h3>
                <h4>
                  {{ precioTotal | currency }}
                </h4>
              </h3>
            </div>
            <fragment v-if="precioSuplementPayOnline > 0">
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.payOnline') }}
                </h4>
                <h4>
                  {{ (precioSuplementPayOnline + cotizadorHotelsResultSelect.priceTotal) | currency }}
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <fragment>
                  <h6>
                    {{ $t('lbl.tarif') }}
                  </h6>
                  <h6>
                    {{ cotizadorHotelsResultSelect.priceTotal | currency }}
                  </h6>
                </fragment>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <fragment>
                  <h6>
                    {{ $t('lbl.cargoSuplement') }}
                  </h6>
                  <h6>
                    {{ precioSuplementPayOnline | currency }}
                  </h6>
                </fragment>
              </div>
            </fragment>

            <fragment v-if="precioSuplementPayRentator > 0">
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.payRent') }}
                </h4>
                <h4>
                  {{ precioSuplementPayRentator | currency }}
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <fragment>
                  <h6>
                    {{ $t('lbl.cargoSuplement') }}
                  </h6>
                  <h6>
                    {{ precioSuplementPayRentator | currency }}
                  </h6>
                </fragment>
              </div>
            </fragment>
          </v-card>-->
        </v-row>
      </fragment>

      <!--MOSTRAR PARA EL CLIENTE-->
      <v-row>
        <fragment v-if="!showOnlyGestor">
          <v-card
            width="100%"
            class="mx-2 pa-4"
          >
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-2">
                {{ $t('lbl.totalPay') }}
              </h4>
              <h4>
                <h4>
                  {{ precioTotal | currency }}
                </h4>
              </h4>
            </div>
            <!--
            <div class="d-flex justify-space-between align-center">
              <h4 class="my-2">
                {{ $t('lbl.cargoSuplement') }}
              </h4>
            </div>
            <div class="d-flex justify-space-between align-center ml-2">
              <h6>
                {{ $t('lbl.seguro') }}
              </h6>
              <h6>
                {{ cotizadorHotelsResultSelect.tarifaSeguro | currency }}
              </h6>
            </div>
            <div class="d-flex justify-space-between align-center ml-2">
              <h6>
                {{ $t('lbl.depositoGarantia') }}
              </h6>
              <h6>
                {{ cotizadorHotelsResultSelect.tarifaGarantia | currency }}
              </h6>
            </div>
            <fragment>
              <div class="d-flex justify-space-between align-center ml-2">
                <h6>
                  {{ $t('lbl.tarifaRetorno') }}
                </h6>
                <h6>
                  {{ precioTarifaRetorno | currency }}
                </h6>
              </div>
            </fragment>
            <br />

            <div
              v-for="(suplemento, indSupl) in cotizadorHotelsResultSelect.suplementos"
              :key="indSupl"
              class="d-flex justify-space-between align-center ml-2"
            >
              <fragment
                v-if="suplemento.suplemento.name.toLowerCase() !== 'tarifa de retorno' && suplemento.check === true"
              >
                <h6>
                  {{ suplemento.suplemento.name }}
                </h6>
                <h6>
                  {{ suplemento.priceAll | currency }}
                </h6>
              </fragment>
            </div>

            <div class="d-flex justify-space-between align-center">
              <h3 class="my-2">
                {{ $t('lbl.totalPay') }}
              </h3>
              <h3>
                <h4>
                  {{ precioTotal | currency }}
                </h4>
              </h3>
            </div>-->
          </v-card>
        </fragment>

        <!--BOTONERA--
        <v-card
          width="100%"
          class="mx-2 mt-3 pa-4 pt-0"
        >
          <v-container grid-list-xs>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  width="100%"
                  block
                  :disabled="declineTerms"
                  @click="addCarItem"
                >
                  <v-icon class="mr-4">
                    {{ icons.mdiCartOutline }}
                  </v-icon>
                  {{ $t('btn.addToCar') }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  width="100%"
                  outlined
                  @click="printItem"
                >
                  {{ $t('btn.printCotization') }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  width="100%"
                  outlined
                  @click="sendItem"
                >
                  {{ $t('btn.sendCotization') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>-->
      </v-row>
    </v-col>

    <SidebarDataClient
      v-model="isSidebarDataClientActive"
      :from="btnName"
      @confirmPrintCotization="confirmPrintCotization"
      @confirmSend="confirmSend"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
  mdiCartOutline,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import SidebarDataClient from '@/views/booking/cotizador/selected/utils/SidebarDataClient.vue'

// import Filtros from './Filtros.vue'
// import Ida from './Ida.vue'
// import IdaBack from './IdaBack.vue'
// import IdaDestinos from './IdaDestinos.vue'

export default {
  components: {
    SidebarDataClient,
  },
  data() {
    return {
      itemsOrigen: [],
      isLoading: false,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      vrad: 0,
      roomsNomAll: [],
      markup: null,
      valueMarkup: null,
      descuento: null,
      valueDescuento: null,
      agregarDescuento: false,
      incluyeComition: 0,
      incluyeDescuento: 0,
      showOnlyGestor: true,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
        mdiCartOutline,
      },
      precioWithMUP: 0,
      precioWithDESC: 0,
      rooms: [],
      permisos: sessionStorage.getItem('permisos_auth'),

      nameCompany: '',
      nameUser: '',
      btnName: '',
      isSidebarDataClientActive: false,
      isSidebarSendCotizationActive: false,
    }
  },
  computed: {
    ...mapState({
      carItems: state => state.app.carItems,
      cotizadorHotelsRomms: state => state.app.cotizadorHotelsRomms,
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      cotizadorHotelsSelectMarkups: state => state.app.cotizadorHotelsSelectMarkups,
      declineTerms: state => state.app.declineTerms,
      clientCarShop: state => state.app.clientCarShop,
    }),
    precioInitial() {
      let priceTotal = 0

      this.cotizadorHotelsRomms.forEach(ocupa => {
        priceTotal += ocupa.price
      })

      return priceTotal
    },
    precioWithMarkup() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.precioWithMUP
        } // USD
        if (this.valueMarkup === '$') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }

      return priceTotal
    },
    precioWithDescuento() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + this.incluyeComition
        } // USD
        if (this.valueMarkup === '$') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
          this.incluyeComition = parseFloat(this.markup)
          priceTotal = parseFloat(priceTotal) + this.incluyeComition
        }
      }

      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = (this.cotizadorHotelsResultSelect.minPrice * parseFloat(this.descuento)) / 100

          // this.incluyeDescuento = (parseFloat(priceTotal) * parseFloat(this.descuento)) / 100
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithDESC = this.incluyeDescuento
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) - this.incluyeDescuento
        } // USD
        if (this.valueDescuento === '$') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = parseFloat(this.descuento)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithDESC = this.incluyeDescuento
          priceTotal = parseFloat(priceTotal) - this.incluyeDescuento
        }
      }

      return priceTotal
    },
    precioTotal() {
      let priceTotal = 0

      this.cotizadorHotelsRomms.forEach(ocupa => {
        priceTotal += ocupa.priceWithDescuento
      })

      return priceTotal

      /* + parseFloat(this.cotizadorHotelsResultSelect.tarifaSeguro)
        + parseFloat(this.cotizadorHotelsResultSelect.tarifaGarantia)

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (suplement.check) {
          priceTotal += suplement.priceAll
        }
      }) */
    },

    /* precioTarifaRetorno() {
      let priceTotal = 0

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (suplement.suplemento.name.toLowerCase() === 'tarifa de retorno' && suplement.check) {
          priceTotal = suplement.priceAll
        }
      })

      return priceTotal
    },
    precioSuplementPayOnline() {
      let priceTotal = parseFloat(this.cotizadorHotelsResultSelect.tarifaSeguro)

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !!suplement.online) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    },
    precioSuplementPayRentator() {
      let priceTotal = parseFloat(this.cotizadorHotelsResultSelect.tarifaGarantia)

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !suplement.online) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    }, */

    generateJsonCar() {
      const suplementos = []

      /* this.cotizadorHotelsResultSelect.suplementos.forEach(element => {
        if (element.check) {
          suplementos.push(element)
        }
      }) */

      // PAY TOTAL
      let priceTotal = this.cotizadorHotelsResultSelect.priceTotal
        + parseFloat(this.cotizadorHotelsResultSelect.tarifaSeguro)
        + parseFloat(this.cotizadorHotelsResultSelect.tarifaGarantia)

      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (suplement.check) {
          priceTotal += suplement.priceAll
        }
      })
      const jsonItem = {
        car: this.cotizadorHotelsResultSelect.car,
        category_id: this.cotizadorHotelsResultSelect.category_id,
        marca_id: this.cotizadorHotelsResultSelect.marca_id,
        contrate_id: this.cotizadorHotelsResultSelect.contrate_id,
        cuposDisponibles: this.cotizadorHotelsResultSelect.cuposDisponibles,
        diasReservar: this.cotizadorHotelsResultSelect.diasReservar,
        priceTotal,

        // priceTotal: this.cotizadorHotelsResultSelect.priceTotal,
        priceWithDescuento: this.cotizadorHotelsResultSelect.priceWithDescuento,
        priceWithMarkup: this.cotizadorHotelsResultSelect.priceWithMarkup,
        rentadora: this.cotizadorHotelsResultSelect.rentadora,
        tarifaAllDay: this.cotizadorHotelsResultSelect.tarifaAllDay,
        tarifaDay: this.cotizadorHotelsResultSelect.tarifaDay,
        tarifaDayExtra: this.cotizadorHotelsResultSelect.tarifaDayExtra,
        tarifaGarantia: this.cotizadorHotelsResultSelect.tarifaGarantia,
        tarifaSeguro: this.cotizadorHotelsResultSelect.tarifaSeguro,
        suplementos,
      }

      let recogida
      if (this.cotizadorCars.recogida.type === 'oficina') {
        recogida = this.cotizadorCars.recogida
      } else if (this.cotizadorCars.recogida.type === 'destino') {
        recogida = this.cotizadorHotelsResultSelect.recogida
      }

      let entrega
      if (this.cotizadorCars.entrega) {
        if (this.cotizadorCars.entrega.type === 'oficina') {
          entrega = this.cotizadorCars.entrega
        } else if (this.cotizadorCars.entrega.type === 'destino') {
          entrega = this.cotizadorHotelsResultSelect.entrega
        }
      } else {
        entrega = recogida
      }

      const jsonReserve = {
        entrega,
        dateEntrega: this.cotizadorCars.dateEntrega,
        recogida,
        dateRecogida: this.cotizadorCars.dateRecogida,
        entregaDiffRecogida: this.cotizadorCars.entregaDiffRecogida,
        items: jsonItem,
      }

      let payOnline = parseFloat(this.cotizadorHotelsResultSelect.tarifaSeguro)
        + parseFloat(this.cotizadorHotelsResultSelect.priceTotal)
      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !!suplement.online) {
          payOnline += suplement.priceAll
        }
      })

      let payRentadora = parseFloat(this.cotizadorHotelsResultSelect.tarifaGarantia)
      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !suplement.online) {
          payRentadora += suplement.priceAll
        }
      })

      let payRetorno = 0
      this.cotizadorHotelsResultSelect.suplementos.forEach(suplement => {
        if (suplement.suplemento.slug === 'tarifa-de-retorno' && suplement.check) {
          payRetorno = suplement.priceAll
        }
      })
      const jsonPrices = {
        priceTotal,
        priceTarifa: this.cotizadorHotelsResultSelect.priceTotal,
        precioSuplementPayOnline: payOnline,
        precioSuplementPayRentator: payRentadora,
        precioTarifaRetorno: payRetorno,
        precioTarifaxKm: 0,
        diasReservar: this.cotizadorHotelsResultSelect.diasReservar,
        edad: this.cotizadorHotelsResultSelect.edad,
        edad_max: this.cotizadorHotelsResultSelect.edad_max,
        conditions_id: this.cotizadorHotelsResultSelect.conditions,
        contrate_id: this.cotizadorHotelsResultSelect.contrate_id,
        proveedor_id: this.cotizadorHotelsResultSelect.proveedor_id,
        markups: this.cotizadorHotelsResultSelect.markups,
        descuento: this.cotizadorHotelsResultSelect.priceWithDescuento,
        descuento_v: this.descuento,
        descuento_value: this.valueDescuento,
        markup: this.cotizadorHotelsResultSelect.priceWithMarkup,
        markup_v: this.markup,
        markup_value: this.valueMarkup,
        suplementos,
        tarifaAllNeto: this.cotizadorHotelsResultSelect.tarifaAllDay,
        tarifaDayNeto: this.cotizadorHotelsResultSelect.tarifaDay,
        tarifaDayExtra: this.cotizadorHotelsResultSelect.tarifaDayExtra,
        tarifaGarantia: this.cotizadorHotelsResultSelect.tarifaGarantia,
        tarifaSeguro: this.cotizadorHotelsResultSelect.tarifaSeguro,
      }

      const json = {
        check: true,
        product: 'cars',
        data_client: this.cotizadorPaxCarSelect,
        data_markups: jsonPrices,
        data_service: jsonReserve,
        data_reserva: {},
        lang: this.$i18n.locale,
        user_id: this.user.id,
      }

      return json
    },
  },
  created() {
    this.getRooms()

    /* const mark = {
      markup: 0,
      valueMarkup: 0,
      descuento: 0,
      valueDescuento: 0,
    }

    console.log(this.cotizadorHotelsRomms)

    this.setCotizadorHotelsSelectMarkups(mark) */

    this.valueMarkup = '%'
    if (!this.cotizadorHotelsSelectMarkups.valueMarkup === '$') {
      this.valueMarkup = '$'
    }

    if (this.cotizadorHotelsSelectMarkups.markup && this.valueMarkup === '%') {
      this.markup = this.cotizadorHotelsSelectMarkups.markup
    } else if (this.cotizadorHotelsSelectMarkups.markup && this.valueMarkup === '$') {
      this.markup = this.cotizadorHotelsSelectMarkups.markup * this.cotizadorHotelsResultSelect.days_search
    }

    this.applyMarkups()

    window.addEventListener('scroll', this.handleScroll)

    if (this.permisos.includes('pvp:list')) {
      window.addEventListener('keydown', e => {
        if ((e.key === 'r' || e.key === 'R') && e.shiftKey) {
          this.changePrice()
        }
      })
    } else {
      this.changePrice()
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations([
      'setCotizadorHotelsSelectMarkups',
      'setCotizadorHotelSelectPrice',
      'updateLoadingBtn',
      'setCotizadorHotelsResult',
      'setCotizadorHotelsResultSelect',
      'setSearchingHotel',
      'emptyClientCarShop',
    ]),
    handleScroll() {
      if (window.scrollY >= 60) {
        this.$refs.statiCard.classList.add('topMine')
      } else {
        this.$refs.statiCard.classList.remove('topMine')
      }
    },
    goBack() {
      this.$router.push({ name: 'cotizador' })
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },
    changeAgregarDescuento(e) {
      if (e === null) {
        this.descuento = null
        this.valueDescuento = null
      }
    },
    applyMarkups() {
      // console.log(this.precioWithDescuento)
      // console.log(this.cotizadorFlightSelect.item_ida.tarifas_contrate[this.cotizadorFlightSelect.indTarifa].minPrice)
      if (this.precioInitial < this.precioWithDescuento) {
        this.setCotizadorHotelsSelectMarkups({
          markup: this.markup,
          valueMarkup: this.valueMarkup,
          descuento: this.descuento,
          valueDescuento: this.valueDescuento,
        })

        /* this.setCotizadorHotelSelectPrice({
          priceTotal: this.precioWithDescuento,
          priceWithMarkup: this.precioWithMUP,
          priceWithDescuento: this.precioWithDESC,
        }) */

        let cont = 0
        this.cotizadorHotelsRomms.forEach(element => {
          cont += element.cant
        })
        const descByRoom = ((this.precioWithDescuento - this.precioInitial) / cont).toFixed(2)

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.cotizadorHotelsRomms.length; i++) {
          this.cotizadorHotelsRomms[i].priceWithDescuento = this.cotizadorHotelsRomms[i].cant * descByRoom + this.cotizadorHotelsRomms[i].priceWithDescuento
        }

        /* this.cotizadorHotels.ocupation.forEach(ocupa => {
          priceAll += ocupa.priceWithDescuento
        }) */
      } else {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },
    getRooms() {
      this.axios
        .get('nom_habitacion_hotels?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.roomsNomAll = res.data.data
          }
        })
    },

    addCarItem() {
      let procesar = true
      if (
        !this.cotizadorPaxCarSelect.nameConductorPrincipal
        || !this.cotizadorPaxCarSelect.apellidosConductorPrincipal
        || !this.cotizadorPaxCarSelect.birthdayConductorPrincipal
        || !this.cotizadorPaxCarSelect.nationalityConductorPrincipal
        || !this.cotizadorPaxCarSelect.noDocumentConductorPrincipal
      ) {
        procesar = false
      }

      if (this.cotizadorPaxCarSelect.conductorAditional) {
        if (
          !this.cotizadorPaxCarSelect.nameConductorAditional
          || !this.cotizadorPaxCarSelect.apellidosConductorAditional
          || !this.cotizadorPaxCarSelect.birthdayConductorAditional
          || !this.cotizadorPaxCarSelect.nationalityConductorAditional
          || !this.cotizadorPaxCarSelect.noDocumentConductorAditional
        ) {
          procesar = false
        }
      }

      if (procesar) {
        const json = this.generateJsonCar

        /* const formData = new FormData()
        if (this.noDocumentConductorPrincipalScanner) {
          formData.append('noDocumentConductorPrincipalScanner', this.noDocumentConductorPrincipalScanner)
        }
        if (this.noDocumentConductorAditionalScanner) {
          formData.append('noDocumentConductorAditionalScanner', this.noDocumentConductorAditionalScanner)
        }
        formData.append('data', JSON.stringify(json)) */

        const cars = localStorage.getItem('car-shop') ? JSON.parse(localStorage.getItem('car-shop')) : []
        cars.push(json)

        localStorage.setItem('car-shop', JSON.stringify(cars))
        this.adicionarItemToCar(json)

        // LIMPIAR COTIZADOR
        this.clearCotizador()
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearCotizador() {
      // REGRESARLO AL COTIZADOR
      setTimeout(() => {
        this.goBack()
      }, 200)
      sessionStorage.setItem('clear-cotization', 'hotels')
      this.setCotizadorHotelsResult([])
      setTimeout(() => {
        /* this.setCotizadorCars({
          recogida: null,
          entrega: null,
          entregaDiffRecogida: false,
          dateRecogida: null,
          dateEntrega: null,
          transmision_id: null,
          rentadora_id: null,
        }) */
        this.setCotizadorHotelsResultSelect([])

        /* this.setCotizadorPaxCarSelect({
          nameConductorPrincipal: null,
          apellidosConductorPrincipal: null,
          nameSecoundConductorPrincipal: null,
          birthdayConductorPrincipal: null,
          nationalityConductorPrincipal: null,
          sexConductorPrincipal: null,
          noDocumentConductorPrincipal: null,
          noDocumentConductorPrincipalScanner: null,
          conductorAditional: false,
          nameConductorAditional: null,
          apellidosConductorAditional: null,
          nameSecoundConductorAditional: null,
          birthdayConductorAditional: null,
          nationalityConductorAditional: null,
          sexConductorAditional: null,
          noDocumentConductorAditional: null,
          noDocumentConductorAditionalScanner: null,
        }) */
        this.setSearchingHotel(false)

        this.emptyClientCarShop()
        this.updateItemToCar([])
        localStorage.removeItem('car-shop')
      }, 100)
    },

    printItem() {
      if (this.carItems.length > 1) {
        this.showModalPrint = true
      } else {
        this.printCotiItems = 'actual'
        this.confirmPrint()
      }
    },
    confirmPrint() {
      this.showModalPrint = false
      this.btnName = 'btn-print'
      setTimeout(() => {
        this.isSidebarDataClientActive = true
      }, 50)

      /* if (this.printCotiItems === 'all') {
        // this.$router.push({ name: 'car-shop-items' })
      } else {
        console.log(this.printCotiItems)
      } */
    },
    confirmPrintCotization() {
      if (this.clientCarShop.name /* && this.clientCarShop.apellidos */ && this.clientCarShop.email) {
        const procesar = true
        this.updateLoadingBtn(true)
        if (procesar) {
          const jsonCar = {
            items: [this.generateJsonCar],
            data_client: this.clientCarShop,
            action: 'print',
          }

          console.log(jsonCar)

          /* this.axios
            .post('reservations/generate-cotization', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR COTIZADOR
                this.clearCotizador()
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)
              this.isSidebarDataClientActive = false
            }) */
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    sendItem() {
      if (this.carItems.length > 1) {
        this.showModalSend = true
      } else {
        this.printCotiItems = 'actual'
        this.confirmSend()
      }
    },
    confirmSend() {
      if (this.clientCarShop.name /* && this.clientCarShop.apellidos */ && this.clientCarShop.email) {
        this.showModalSend = false
        setTimeout(() => {
          this.isSidebarDataClientActive = false
          this.isSidebarSendCotizationActive = true
        }, 50)
      } else {
        this.showModalSend = false
        this.btnName = 'btn-send'
        setTimeout(() => {
          this.isSidebarDataClientActive = true
        }, 50)
      }
    },
    confirmSendCotization() {
      const procesar = true
      if (procesar) {
        this.updateLoadingBtn(true)
        const jsonCar = {
          items: [this.generateJsonCar],
          data_client: this.clientCarShop,
          action: 'send',
        }

        this.axios
          .post('reservations/generate-cotization-send', jsonCar, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              this.$toast.success(this.$t('msg.sendCotization'))

              // LIMPIAR COTIZADOR
              setTimeout(() => {
                this.clearCotizador()
              }, 100)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.updateLoadingBtn(false)
            this.isSidebarSendCotizationActive = false
          })
      }
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
