<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment>
    <v-col cols="12">
      <div class="d-block">
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="ml-0 mr-0 pb-0 mb-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="ml-0 mr-0 pb-0 mb-0"
              >
                {{ `${$t('lbl.room')} ${index + 1}` }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="ml-0 mr-0 pb-0 mb-0"
              >
                <v-icon
                  v-for="adul in ocupation.adults"
                  :key="Math.random() * (adul + 1)"
                  large
                  class="ml-0 mr-0 text-end text-right"
                >
                  mdi-human-male
                </v-icon>
                <v-icon
                  v-for="child in ocupation.childs"
                  :key="Math.random() * (child + 1)"
                  class="ml-0 mr-0 mt-2 text-end text-right"
                >
                  mdi-human-child
                </v-icon>
              </v-col>
              <v-col
                cols="12"
                md="8"
                class="ml-0 mr-0 pb-0 mb-0"
              >
                <!--<v-autocomplete
                  v-model="ocupation.room_id"
                  class="pt-2"
                  :items="itemsRooms"
                  :search-input.sync="searchRooms"
                  hide-details
                  :label="$t('lbl.room')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  clearable
                  @change="changeRoom"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.room') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="nameRoomWithPrice(item.name, item.id)"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ nameRoomWithPrice(item.name, item.id) }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>-->
                <v-select
                  v-model="ocupation.room_id"
                  :items="roomsHotel"
                  :label="$t('lbl.room')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  clearable
                  @change="changeRoom"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.room') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="nameRoomWithPrice(item.name, item.id)"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ nameRoomWithPrice(item.name, item.id) }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>

          <fragment>
            <v-col
              cols="12"
              md="4"
              class="ml-0 mr-0 pt-0 mt-0"
            >
              <!--<h5>
                {{ $t('lbl.infoHuesp') }}
                <fragment v-if="showAll && ocupation.adults + ocupation.childs > 1">
                  <v-tooltip
                    v-if="cotizadorHotels.ocupation[index].showAllPaxs === false"
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="setShowAllOcupation()"
                      >
                        <v-icon>mdi-account-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('lbl.addDataHuesp') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="setShowAllOcupation()"
                      >
                        <v-icon>mdi-account-minus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('lbl.deleteDataHuesp') }}</span>
                  </v-tooltip>
                </fragment>
              </h5>-->
              <v-switch
                v-if="ocupation.adults + ocupation.childs > 1"
                v-model="showAllPaxsOcupation"
                :label="$t('lbl.roomingList')"
                hide-details
                class="pt-0 pb-0 mt-0 mb-0"
                @change="setShowAllOcupation"
              ></v-switch>
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="ml-0 mr-0 pt-0 mt-0"
            >
              <v-radio-group
                v-model="ocupation.plan"
                row
                class="pt-0 pb-0 mt-0 mb-0"
                @change="changePlan"
              >
                <v-radio
                  v-for="(regimen, indeR) in regimenes"
                  :key="indeR"
                  :label="regimen.siglas"
                  :value="regimen.siglas"
                  class="pt-0 pb-0 mt-0 mb-0"
                  no-gutters
                ></v-radio>
              </v-radio-group>
            </v-col>
          </fragment>

          <fragment v-if="showAll">
            <HotelRoomPax
              v-for="(pax, indexPax) in ocupation.paxs"
              :key="indexPax"
              :ocupation="ocupation"
              :pax="pax"
              :index="indexPax"
              :index-ocupation="index"
            />
          </fragment>
        </v-row>
      </div>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
} from '@mdi/js'

import HotelRoomPax from './HotelRoomPax.vue'

export default {
  components: {
    HotelRoomPax,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    regimenes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    ocupation: {
      type: Object,
    },
    // eslint-disable-next-line vue/prop-name-casing, vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      image: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      recogida: null,
      entrega: null,
      vrad: 0,
      value: null,
      value1: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
      },
      infoImportant: false,
      is_nivel_propietario: false,
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      condicionesGenerales_id: [],
      checkbox1: true,
      services: [],
      roomsNomAll: [],
      roomsHotel: [],
      itemsRooms: [],
      searchRooms: null,
      showAll: true,
      showAllPaxsOcupation: false,
    }
  },
  computed: {
    ...mapState({
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      cotizadorPaxCarSelect: state => state.app.cotizadorPaxCarSelect,
    }),
    cantAdults() {
      let cantA = 0
      this.cotizadorHotelsResultSelect.ocupation.forEach(element => {
        cantA += element.adults
      })

      return cantA
    },
    cantMenor() {
      let cantM = 0
      this.cotizadorHotelsResultSelect.ocupation.forEach(element => {
        cantM += element.childs
      })

      return cantM
    },
  },
  watch: {
    searchRooms(val) {
      if (val !== null && val.length > 1) {
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  created() {
    this.profile()
    this.getRooms()
  },
  methods: {
    ...mapMutations([
      'updateOcupationHotel',
      'setCotizadorHotelSelectPriceTotal',
      'setShowRoomHotelCotizador',
      'setCotizadorHotelsRomms',
      'setOcupationHotelShowAll',
      'updatePlanOcupationHotel',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
            this.is_nivel_propietario = true
            this.infoImportant = true
          }
        })
        .catch(error => console.log(error))
    },
    filterRooms(v) {
      this.itemsRooms = []
      if (v === '') {
        this.itemsRooms = []
      } else {
        const result = this.roomsHotel.filter(e => e.name.toLowerCase())
        result.forEach(element => {
          this.itemsRooms.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
    initRoom() {
      if (this.cotizadorHotelsResultSelect.rooms.filter(e => e.room_id === this.ocupation.room_id).length > 0) {
        const habInit = this.cotizadorHotelsResultSelect.rooms.filter(e => e.room_id === this.ocupation.room_id)[0]
        const hhh = this.roomsHotel.filter(e => e.id === habInit.room_id)
        if (hhh.length > 0) {
          this.itemsRooms.push({
            id: hhh[0].id,
            name: hhh[0].name,
          })
        }
      }
    },
    nameRoomWithPrice(name, roomId) {
      let priceForRoom = 0
      const hab = this.cotizadorHotelsResultSelect.rooms.filter(e => e.room_id === roomId)[0]
      hab.days.forEach(day => {
        day.pricesSeasonDay.forEach(pricesSeasonD => {
          if (pricesSeasonD.pax === 'sgl' && this.ocupation.adults === 1) {
            priceForRoom += pricesSeasonD.price
          } else if (pricesSeasonD.pax === 'dbl' && this.ocupation.adults === 2) {
            priceForRoom += pricesSeasonD.price * this.ocupation.adults
          } else if (pricesSeasonD.pax === 'tpl' && this.ocupation.adults === 3) {
            priceForRoom += pricesSeasonD.price * this.ocupation.adults
          } else if (pricesSeasonD.pax === 'pax4' && this.ocupation.adults === 4) {
            priceForRoom += pricesSeasonD.price * this.ocupation.adults
          } else if (pricesSeasonD.pax === 'pax5' && this.ocupation.adults === 5) {
            priceForRoom += pricesSeasonD.price * this.ocupation.adults
          } else if (pricesSeasonD.pax === 'pax6' && this.ocupation.adults === 6) {
            priceForRoom += pricesSeasonD.price * this.ocupation.adults
          } else if (pricesSeasonD.pax === 'pax7' && this.ocupation.adults === 7) {
            priceForRoom += pricesSeasonD.price * this.ocupation.adults
          } else if (pricesSeasonD.pax === 'pax8' && this.ocupation.adults === 8) {
            priceForRoom += pricesSeasonD.price * this.ocupation.adults
          }
        })
      })

      return `${name} ($${priceForRoom})`
    },
    getRooms() {
      this.axios
        .get('nom_habitacion_hotels?page=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.roomsNomAll = res.data.data

            this.cotizadorHotelsResultSelect.rooms.forEach(room => {
              this.roomsNomAll.forEach(roomN => {
                if (room.room_id === roomN.id) {
                  // this.roomsHotel.push(roomN)
                  this.roomsHotel.push({
                    id: roomN.id,
                    name: roomN.name,
                  })
                }
              })
            })
          }
        })

      // .finally(() => this.initRoom())
    },
    changeRoom() {
      let priceForRoom = 0
      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)
      let plan = ''
      let uso = ''
      if (this.ocupation.room_id) {
        const hab = this.cotizadorHotelsResultSelect.rooms.filter(e => e.room_id === this.ocupation.room_id)[0]
        plan = hab.plan
        hab.days.forEach(day => {
          day.pricesSeasonDay.forEach(pricesSeasonD => {
            if (pricesSeasonD.pax === 'sgl' && this.ocupation.adults === 1) {
              priceForRoom += pricesSeasonD.price
              uso = 'sgl'
            } else if (pricesSeasonD.pax === 'dbl' && this.ocupation.adults === 2) {
              priceForRoom += pricesSeasonD.price * this.ocupation.adults
              uso = 'dbl'
            } else if (pricesSeasonD.pax === 'tpl' && this.ocupation.adults === 3) {
              priceForRoom += pricesSeasonD.price * this.ocupation.adults
              uso = 'tpl'
            } else if (pricesSeasonD.pax === 'pax4' && this.ocupation.adults === 4) {
              priceForRoom += pricesSeasonD.price * this.ocupation.adults
              uso = 'pax4'
            } else if (pricesSeasonD.pax === 'pax5' && this.ocupation.adults === 5) {
              priceForRoom += pricesSeasonD.price * this.ocupation.adults
              uso = 'pax5'
            } else if (pricesSeasonD.pax === 'pax6' && this.ocupation.adults === 6) {
              priceForRoom += pricesSeasonD.price * this.ocupation.adults
              uso = 'pax6'
            } else if (pricesSeasonD.pax === 'pax7' && this.ocupation.adults === 7) {
              priceForRoom += pricesSeasonD.price * this.ocupation.adults
              uso = 'pax7'
            } else if (pricesSeasonD.pax === 'pax8' && this.ocupation.adults === 8) {
              priceForRoom += pricesSeasonD.price * this.ocupation.adults
              uso = 'pax8'
            }
          })
        })
      }

      this.updateOcupationHotel({
        pos: this.index,
        room_id: this.ocupation.room_id,
        plan,
        uso,
        priceTotal: priceForRoom,
        priceWithMarkups: priceForRoom,
        priceWithDescuento: priceForRoom,
      })

      let priceAll = 0
      this.cotizadorHotels.ocupation.forEach(ocupa => {
        priceAll += ocupa.priceWithDescuento
      })

      this.setCotizadorHotelsRomms()
      this.setCotizadorHotelSelectPriceTotal(priceAll)

      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)
    },
    setShowAllOcupation() {
      this.showAll = false

      // const json = { pos: this.index, value: !this.cotizadorHotels.ocupation[this.index].showAllPaxs }
      const json = { pos: this.index, value: this.showAllPaxsOcupation }
      this.setOcupationHotelShowAll(json)

      setTimeout(() => {
        this.showAll = true
      }, 50)
    },
    changePlan() {
      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)
      this.updatePlanOcupationHotel({
        pos: this.index,
        room_id: this.ocupation.room_id,
        plan: this.ocupation.plan,

        // priceTotal: priceForRoom,
        // priceWithMarkups: priceForRoom,
        // priceWithDescuento: priceForRoom,
      })

      this.setCotizadorHotelsRomms()

      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
